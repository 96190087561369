/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Icon from './../../Icon'

/**
 * Renders a horizontal list of all projects.
 */
class HorizontalList extends Component {
  render () {
    return (
      <>
        <StaticQuery query={graphql`
          query {
            allMarkdownRemark (
              sort: {fields: [frontmatter___date], order: DESC}
              filter: {
                fields:{
                  collection: {eq: "projects"}
                }
              }
           ){
            edges{
             node{
               frontmatter{
                cover_image{
                 publicURL
                 childImageSharp{
                   fluid {
                     ...GatsbyImageSharpFluid
                   }
                 }
                }
             date(formatString: "Do MMMM, YYYY")
                  updated(formatString: "Do MMMM, YYYY")
                  title
                  path
                  tags
                  description
                  ci_badge_url
                  ci_badge_alt
                  fury_badge_url
                  fury_badge_image_url
                  fury_badge_alt
                }
              }
            }
          }
        }
        `}
        render={data =>
          <div className="projects-group">
            <div className="row">
              {data.allMarkdownRemark.edges.map(({ node }, index, arr) => (
                <div className="col-12 project-item" key={index}>
                  <div className="card h-100">
                    <Link to={node.frontmatter.path} title={node.frontmatter.title}>
                      <Img
                        fluid={node.frontmatter.cover_image.childImageSharp.fluid}
                        className={`img-fluid card-img-top`}
                      />
                    </Link>
                    <div className="col-12 p-3 card-body">
                      <Link
                        to={node.frontmatter.path}
                        title={node.frontmatter.title}
                        className={`hover-none`}
                      >
                        <h3 className="hover-lighten">
                          {node.frontmatter.title}
                        </h3>
                      </Link>
                      <div>
                        <small className="text-muted">
                          Released on {node.frontmatter.date} | Last update on {node.frontmatter.updated}
                        </small>
                      </div>
                      <div className="tags">
                        {node.frontmatter.tags.map(tag =>
                          <span className="badge badge-secondary mr-2" key={tag}>
                            {tag}
                          </span>
                        )}
                      </div>
                      {((node.frontmatter.ci_badge_url) ||
                        (node.frontmatter.fury_badge_url)) ? (
                          <p className={`pt-3 pb-3`}>
                            {node.frontmatter.ci_badge_url ? (
                              <img
                                src={node.frontmatter.ci_badge_url}
                                alt={node.frontmatter.ci_badge_alt}
                              />
                            ) : (null)}
                            {node.frontmatter.fury_badge_url ? (
                              <a
                                className={`ml-3`}
                                target={`_blank`}
                                rel={`noopener noreferrer`}
                                href={node.frontmatter.fury_badge_url}
                                title={node.frontmatter.fury_badge_alt}
                              >
                                <img
                                  src={node.frontmatter.fury_badge_image_url}
                                  alt={node.frontmatter.fury_badge_alt}
                                />
                              </a>
                            ) : (null)}
                          </p>
                      ) : (null)}
                      <p className="text-secondary small pt-5 excerpt">
                        {node.frontmatter.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        }
        />
      </>
    )
  }
}

export default HorizontalList
