/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { isLastElementInArray, isDesktop } from '../../../../lib/helpers'

/**
 * Renders a vertical list of all projects.
 */
class VerticalList extends Component {
  /**
   * Toggles between left and right depending on
   * whether the index is even or odd.
   *
   * @param itemIndex
   * @return {string}
   */
  getAlignment = itemIndex => {
    let alignment = 'right'
    if (isDesktop()) {
      if (itemIndex % 2 === 0) {
        alignment = 'left'
      }
    } else {
      alignment = 'left'
    }
    return alignment
  }

  /**
   * Gets a linked preview image.
   *
   * @param projectPath
   * @param projectTitle
   * @param fluidImage
   * @param imageAlignment
   * @return {*}
   */
  getPreviewImage = (projectPath, projectTitle, fluidImage, imageAlignment) => {
    let borderAlignment = 'rounded-' + imageAlignment
    return (
      <Link to={projectPath} title={projectTitle}>
        <Img
          fluid={fluidImage}
          className={`img-fluid ${ borderAlignment }`}
        />
      </Link>
    )
  }

  render () {
    return (
      <>
        <StaticQuery query={graphql`
          query {
            allMarkdownRemark (
              sort: {fields: [frontmatter___date], order: DESC}
              filter: {
                fields:{
                  collection: {eq: "projects"}
                }
              }
            ){
              edges{
                node{
                  id
                  frontmatter{
                    cover_image{
                      publicURL
                        childImageSharp{
                          fluid {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                  date(formatString: "Do MMMM, YYYY")
                  updated(formatString: "Do MMMM, YYYY")
                  title
                  path
                  tags
                  description
                  ci_badge_url
                  ci_badge_alt
                  fury_badge_url
                  fury_badge_image_url
                  fury_badge_alt
                }
              }
            }
          }
        }
      `}
        render={data =>
          <div className="row">
            {data.allMarkdownRemark.edges.map(({ node }, index, arr) => (
              <div key={index} className={`col-12 ${ isLastElementInArray(index, arr.length) === false && `mb-8` }`}>
                <div className="border rounded row no-gutters">
                  {this.getAlignment(index) === 'left' &&
                    <div className="col-lg-6 col-sm-12">
                      {this.getPreviewImage(
                        node.frontmatter.path,
                        node.frontmatter.title,
                        node.frontmatter.cover_image.childImageSharp.fluid,
                        this.getAlignment(index)
                      )}
                    </div>
                  }
                  <div className="col-lg-6 col-sm-12 py-3 px-3">
                    <Link
                      to={node.frontmatter.path}
                      title={node.frontmatter.title}
                      className={`hover-none`}
                    >
                      <h3 className={`hover-lighten`}>
                        {node.frontmatter.title}
                      </h3>
                    </Link>
                    <div>
                      <small className="text-muted">
                        Released on {node.frontmatter.date} | Last update on {node.frontmatter.updated}
                      </small>
                    </div>
                    <div className="tags pt-3">
                      {node.frontmatter.tags.map(tag =>
                        <span key={tag} className="badge badge-secondary mr-2">
                          {tag}
                        </span>
                      )}
                    </div>
                    {((node.frontmatter.ci_badge_url) ||
                      (node.frontmatter.fury_badge_url)) ? (
                        <p className={`pt-3 pb-3`}>
                          {node.frontmatter.ci_badge_url ? (
                            <img
                              src={node.frontmatter.ci_badge_url}
                              alt={node.frontmatter.ci_badge_alt}
                            />
                          ) : (null)}
                          {node.frontmatter.fury_badge_url ? (
                            <a
                              className={`ml-3`}
                              target={`_blank`}
                              rel={`noopener noreferrer`}
                              href={node.frontmatter.fury_badge_url}
                              title={node.frontmatter.fury_badge_alt}
                            >
                              <img
                                src={node.frontmatter.fury_badge_image_url}
                                alt={node.frontmatter.fury_badge_alt}
                              />
                            </a>
                          ) : (null)}
                        </p>
                    ) : (null)}
                    <p className="text-secondary small pt-3">
                      {node.frontmatter.description}
                    </p>
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '0',
                        right: '0'
                      }}
                      className={`pr-3`}
                    >
                    </div>
                  </div>
                  {this.getAlignment(index) === 'right' &&
                    <div className="col-lg-6 d-sm-none d-md-block">
                      {this.getPreviewImage(
                        node.frontmatter.path,
                        node.frontmatter.title,
                        node.frontmatter.cover_image.childImageSharp.fluid,
                        this.getAlignment(index)
                      )}
                    </div>
                  }
                </div>
              </div>
            ))}
          </div>
        }
        />
      </>
    )
  }
}

export default VerticalList
