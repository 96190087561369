/**
 * This file is part of Keith-mifsud.me
 *
 * @licence Please view the Licence file supplied with this source code.
 *
 * @author Keith Mifsud <https://www.keith-mifsud.me>
 *
 * @copyright Keith Mifsud 2019 <mifsud.k@gmail.com>
 *
 * @since   2.0
 * @version 2.0 Second Major Release
 */
import React, { Component } from 'react'
import DefaultLayout from './../layouts/Default'
import SEO from './../components/Seo'
import config from './../data/SiteConfig'
import AllProjects from './../components/Projects/All'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import Cta from '../components/Cta'

/**
 * Projects page, includes a list of projects.
 */
class ProjectsPage extends Component {
  render () {
    return (
      <DefaultLayout className={`page-projects`}>
        <SEO
          pageType={`article-list`}
          pageTitle={`List of open source projects`}
          fullPageTitle={`List of open source projects by Keith Mifsud`}
          pageDescription={`A collection of open source projects developed by Keith Mifsud. Projects range from libraries, packages and tutorial material.`}
          pageImage={config.siteImage}
          pagePath={`/projects`}
        />
        <div className="container">
          <div className="row pt-lg-12 pt-8 special-padding intro">
            <section className="col-12 content text-center">
              <h1>List of open source projects</h1>
              <p className="text-secondary lead ps-lg-8 pt-3">
                Check out my <a
                href={config.defaultAuthor.socialProfiles.gitHub}
                target={`_blank`}
                rel={`noopener noreferrer`}
                className={`hover-darken hover-none`}
              >GitHub profile</a> 😉
              </p>
              <p className={`pt-3`}><Link to={`/subscribe`} className={`hover-none hover-darken`} title={`Subscribe`}>Subscribe</Link> to get notified when I release new projects.</p>
              <div className="row justify-content-center pt-lg-0-pt-3">
                <div className="col-lg-2 col-4">
                  <p className="icons">
                    <a
                      href={config.defaultAuthor.socialProfiles.twitter}
                      className="mr-3 hover-darken"
                      title={`Follow Keith Mifsud on Twitter`}
                      target={`_blank`}
                      rel={`noopener noreferrer`}
                      style={{ fontSize: '1.6rem' }}
                    >
                      <FontAwesomeIcon icon={['fab', 'twitter']}/>
                    </a>
                    <a
                      href={config.siteUrl + config.rssFeedPath}
                      className="hover-darken"
                      target={`_blank`}
                      rel={`noopener noreferrer`}
                      style={{ fontSize: '1.6rem' }}
                    >
                      <FontAwesomeIcon icon={['fas', 'rss']}/>
                    </a>
                  </p>
                </div>
              </div>
              <div className="row d-sm-none justify-content-center pt-3">
                <div className="col-6">
                  <AnchorLink href={`#start-list`}>
                    <button className={`btn btn-outline-secondary btn-lg`} style={{ maxHeight: '48px' }}>
                      View <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '0.875rem' }}/>
                    </button>
                  </AnchorLink>
                </div>
              </div>
            </section>
          </div>
          <div className="row pt-lg-10 pt-10" id={`start-list`}>
            <div className="col-lg-9 list">
              <div className="d-none d-sm-block">
                <AllProjects orientation={'vertical'}/>
              </div>
              <div className="d-sm-none recent-articles-container">
                <AllProjects orientation={'horizontal'}/>
              </div>
            </div>
            <aside className="col-lg-3 pt-lg-0 pt-5">
              <div
                className="sidebar-content border ml-lg-5 sticky-top sticky-offset-header rounded"
              >
                <Cta
                  type={`service`}
                  serviceName={`development`}
                  actionText={`Get in touch`}
                  serviceLink={`/services/full-stack-development`}
                >
                  <div className="cta-content">
                    <p><strong>Need help with VueJS, NuxtJS or Laravel?</strong></p>
                    <p>I can help you as Full-stack developer wth NuxtJS, VueJS and Laravel. I can also assist with organising deployment systems and setting up the infrastructure.</p>
                  </div>
                </Cta>
              </div>
            </aside>
          </div>
        </div>
      </DefaultLayout>
    )
  }
}

export default ProjectsPage
